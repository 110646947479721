<template>
  <div class="variables-etudiees-edit-subview">
    <div class="tabs-material-subtitle no-padding-bottom">
      <Container>
        <div class="title-with-button">
          <h2>Variables étudiées</h2>
          <Btn color="primary" v-if="helperService.userHasPermission('essaievenementnotationvariable_oadd')" hollow
            text="Ajouter une variable" :to="{
              name: 'protocolExperimentalTaskVariableStudiedSettingsAdd',
              params: {
                id: $route.params.id,
                tid: $route.params.tid
              }
            }" />
        </div>
      </Container>
    </div>

    <Section>
      <List v-if="!isLoading" :api="list.api" :listData="dataList" :header="list.headers" :items="list.col" :item-key="list.key" isDraggable
        @list-drag-change="changeDataList($event)">
        <template v-slot:group-actions="{ selectedItems }">
          <Btn v-if="helperService.userHasPermission('essaievenementnotationvariable_oremove')" text="Supprimer"
            icon="trash-alt" color="default" @click="openModal('deleteItems', selectedItems)" />
        </template>
        <template v-slot:cible="{ item }">
          {{ item?.cible?.valeur }}
        </template>
        <template v-slot:saisie_niveau="{ item }">
          <template v-if="item.saisie_niveau">
            <div>{{ item?.saisie_niveau.designation }}</div>
            <div v-if="item.saisie_niveau.uid === 'ORGANE'">
              Organe:&nbsp;{{ item.saisie_niveau.organe?.designation }}<br />
              Nombre d'échantillons:&nbsp;{{ item.saisie_niveau.organe?.nb_echantillons }}
            </div>
          </template>
        </template>

        <template v-slot:actions="{ item }">
          <Btn icon="create" v-if="helperService.userHasPermission('essaievenementnotationvariable_oedit')" default round
            grow :to="{
              name: 'protocolExperimentalTaskVariableStudiedSettingsEdit',
              params: {
                id: $route.params.id,
                tid: $route.params.tid,
                veid: item.id
              }
            }" />
        </template>
      </List>
    </Section>
  </div>
  <Modal title="Supprimer des variables&nbsp;?" :active="modal.deleteItems" :data="modalData"
    @modal-close="modal.deleteItems = false">
    <template v-slot:modal-body="{ data }">
      <p>
        Voulez vous vraiment supprimer
        {{ data?.length }} variables&nbsp;?
      </p>
    </template>
    <template v-slot:modal-footer="{ data }">
      <Btn text="Annuler" @click="modal.deleteItems = false" />
      <Btn text="Supprimer" @click="deleteItems(data)" icon="trash-alt" color="primary" />
    </template>
  </Modal>
</template>

<script>
import Btn from '@/components/base/Btn.vue'
import List from '@/components/list/List.vue'
import Container from '@/components/layout/Container.vue'
import Section from '@/components/layout/Section.vue'
import Modal from '@/components/layout/Modal.vue'

export default {
  name: 'VariablesStudiedList',
  components: {
    Btn,
    List,
    Container,
    Section,
    Modal,
  },

  props: {
    pageTitle: {
      type: String,
    },
    task: {
      type: Object,
    },
  },

  data() {
    return {
      isLoading: true,
      modal: {
        deleteItems: false,
      },
      modalData: {},
      notation: {},
      lastDataList: [],
      dataList: [],
      list: {
        api: {
          endpoint: null,
          params: {
            sort: 'id.ASC',
            page: null,
            limit: 0,
            filters: null,
          },
        },
        headers: [
          { label: 'Designation' },
          { label: 'Niveau de saisie' },
          { label: 'Cible' },
        ],
        col: ['designation', 'saisie_niveau', 'cible'],
        key: 'id',
      },
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    async getData() {
      this.isLoading = true
      const endpoint = `protocole/${this.$route.params.id}/evenement/${this.$route.params.tid}/variable`
      const variables = await this.fetchService.get(endpoint, { limit: 0 })

      Promise.all(
        variables.data.map(async (variable) => {
          const niveau = await this.fetchService.get(`${endpoint}/${variable.id}/organe`)
          if (niveau.data.length > 0) {
            // eslint-disable-next-line no-param-reassign
            variable.saisie_niveau.organe = niveau.data.pop()
          } else {
            // eslint-disable-next-line no-param-reassign
            variable.saisie_niveau.organe = null
          }
        }),
      ).then(() => {
        this.dataList = this.helperService.cloneObject(variables.data)
        console.log(this.dataList)
        this.isLoading = false
      })
    },

    deleteItems(itemsToDelete) {
      this.emitter.emit('open-loader')

      itemsToDelete.forEach((item) => {
        const { params } = this.$route
        this.fetchService.delete(
          `protocole/${params.id}/evenement/${params.tid}/variable/${item.id}`,
        ).then(
          () => {
            this.emitter.emit('list-refresh')
            this.emitter.emit('alert', {
              type: 'success',
              content: `La variable ${item.id} à été supprimée avec succès.`,
            })
            this.getData()
          },
          (responseError) => {
            this.emitter.emit('alert', {
              type: 'error',
              content: responseError.data,
            })
          },
        )
      })

      this.emitter.emit('list-refresh')
      this.emitter.emit('close-loader')
      this.modal.deleteItems = false
    },
    openModal(modal, datas) {
      this.modalData = datas
      this.modal[modal] = true
    },
    changeDataList(datas) {
      this.lastDataList = this.dataList
      this.dataList = datas
      this.dataList = this.dataList.map((data, index) => {
        // eslint-disable-next-line no-param-reassign
        data.ordre = index + 1
        return data
      })
      let toSend = []
      if (this.lastDataList.length > 0) {
        toSend = this.dataList.map((data, index) => (data.id !== this.lastDataList[index].id
          ? data
          : null))
      }
      toSend = toSend.filter((data) => data !== null)
      toSend.forEach((data) => {
        const params = { ...data }
        params.saisie_niveau_uid = data.saisie_niveau?.uid
        params.type_uid = data.type?.uid ?? null
        params.unite_uid = data.unite?.uid ?? null
        params.cible_id = data.cible?.id ?? null
        delete params.route

        this.updateDatas(params, data)
      })
    },
    updateDatas(params, data) {
      const route = this.$route.params
      if (data.supprime === false) {
        this.fetchService.put(
          `protocole/${route.id}/evenement/${route.tid}/variable/${data.id}`,
          params,
        ).then(() => {
          console.log('success')
        })
      }
    },
  },
}
</script>
